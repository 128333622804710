@import '../../../../styles/common.less';

.sign_up_panel {
  .auth_content_inner_panel();

  .password_strength_wrapper {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    :global {
      label {
        margin-right: 10px;
      }

      .password_strength {
        flex: auto;
      }
    }
  }
}

@primary-color: #c43a58;