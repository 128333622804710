.password_strength {
  display: flex;
  align-items: stretch;
  justify-content: center;
  background: #ddd;
  border-radius: 6px;
  height: 14px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  .strength_bar {
    width: 25%;
    transition: all 0.3s;

    //border-left: 1px solid #fff;
    //border-right: 1px solid #fff;

    &.weak {
      //background: var(--ant-error-color);
      background: #ff4d4f;
    }

    &.medium {
      //background: var(--ant-warning-color);
      background: #faad14;
    }

    &.strong {
      //background: var(--ant-warning-color);
      background: #52c41a;
    }
  }

  .strength_tip {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 1;
    text-align: center;
    font-size: 12px;
    z-index: 1;
    color: #fff;
  }
}

@primary-color: #c43a58;