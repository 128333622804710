.auth_layout {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;

  &:after {
    content: '';
    background-image: url('../../assets/auth_bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //width: 100%;
    //height: 100%;
    opacity: 0.2;
  }
  //&.flow360 {
  //  &:after {
  //    background-image: url('../../assets/flow360_bg.png');
  //  }
  //}
  //&.tidy3d {
  //  &:after {
  //    background-image: url('../../assets/tidy3d_bg.png');
  //  }
  //}
  .auth_content_wrapper {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    z-index: 5;

    .logo_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
      //.logo {
      //width: 70px;
      //height: 54px;
      //background-image: url('../../assets/logo.svg');
      //background-size: cover;
      //background-position: center;
      //background-repeat: no-repeat;
      //cursor: pointer;
      //}
    }

    .auth_content {
      width: 500px;
      margin: 0 auto;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 10%);
      background: #fff;
      border-radius: 5px;
      padding: 20px 40px;
      @media (max-width: 600px) {
        width: 100%;
      }
      .ant-input,
      .ant-input-password,
      .ant-btn {
        border-radius: 4px;
      }
      .ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
          border-radius: 4px;
        }
      }
      .sign_up_btn {
        height: 46px;
        padding: 0 15px;
        font-size: 20px;
        border-radius: 4px;
        line-height: 44px;
      }
    }
  }
}

@primary-color: #c43a58;