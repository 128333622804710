@import '../../../../styles/common.less';

.challenge_panel {
  .auth_content_inner_panel();
  .opt_input {
    justify-content: space-between;
    margin-top: 10px;
    .input_item {
      width: 12% !important;
      height: 60px;
      //line-height: 60px;
      padding: 0;
      font-size: 30px;
      font-weight: bold;
      &::selection {
        background: rgba(209, 96, 117, 0.8);
      }
    }
  }
}

@primary-color: #c43a58;