.resend_email_modal {
  .modal_content {
    //min-height: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    .content_title {
      font-size: 16px;
      font-weight: bold;
      .anticon {
        font-weight: bold;
        margin-right: 10px;
        //color: var(--ant-warning-color);
      }
    }
    .info_content {
      font-size: 14px;
      //font-weight: bold;
      margin-top: 8px;
      margin-left: 26px;
      .success_msg {
        font-size: 14px;
        //font-weight: bold;
      }
      //text-align: center;
    }

    .content_footer {
      margin-top: 24px;
      text-align: right;
    }
  }
}

@primary-color: #c43a58;