//html {
//  body {
//    font-size: 16px;
//  }
//}

.auth_content_inner_panel {
  //text-align: center;
  //box-shadow: 0 2px 5px rgba(0, 0, 0, 10%);
  //background: #fff;
  //border-radius: 5px;
  //padding: 40px;

  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
  }

  .form {
    :global {
      .ant-col-0 {
        display: none;
      }
    }
  }

  .footer {
  }
  .text {
    text-align: center;
    color: #6c757d;
    font-size: 14px;

    .link {
      color: #060606;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
