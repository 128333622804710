@import '../../../styles/common.less';

.anduril_login_page {
  .login_panel {
    .auth_content_inner_panel();
    text-align: center;
    .welcome {
      font-size: 20px;
      margin-top: 24px;
    }
    .intro {
      font-size: 14px;
      color: #333333;
      margin-top: 10px;
    }
    .sign_in_btn {
      border-radius: 4px;
      background: linear-gradient(180deg, #e45776 0%, #c43958 100%);
      box-shadow: 0px 1px 5px 0px #bf7c8b;
      margin-top: 24px;
      width: 120px;
      height: 32px;
      padding: 0;
    }
  }
}

@primary-color: #c43a58;