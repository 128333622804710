.home_page {
  .nav_bar {
    position: fixed;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    z-index: 10;
    .logo_link {
    }

    .product_link {
      background: #c43959;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      color: #fff;
      font-weight: 600;
      padding: 0 18px;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 0 5px rgba(60, 113, 211, 0.2);
      }

      transition: all 0.3s;
    }
  }
  //.google_icon {
  //  background-image: url('../../assets/google_icon.svg');
  //  background-repeat: no-repeat;
  //  background-position: 10px center;
  //  background-size: 14px;
  //}
  .google_login {
    //background-image: url('../../assets/google_icon.svg') !important;
    //background-repeat: no-repeat !important;
    //background-position: 10px center !important;
    //background-size: 14px !important;
    //.google_icon();
    //&:hover,
    //&:disabled {
    //  .google_icon();
    //  &:hover {
    //    .google_icon();
    //  }
    //}
  }
}
.loadable_loading_mask {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
}

@primary-color: #c43a58;